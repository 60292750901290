import React from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import styled from "styled-components";

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e9f2 100%);
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  margin: 20px 0;
  background: linear-gradient(135deg, #2193b0 0%, #1565c0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
`;

const Message = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  color: #4a5568;
  margin: 20px 0;
  max-width: 600px;
  line-height: 1.6;
`;

const Button = styled.button`
  font-family: "Poppins", sans-serif;
  background: linear-gradient(135deg, #2193b0 0%, #1565c0 100%);
  color: white;
  border: none;
  padding: 12px 32px;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-top: 20px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(33, 147, 176, 0.3);
  }
`;

const CheckmarkSVG = styled.svg`
  width: 120px;
  height: 120px;
  margin: 30px;
  padding: 10px;
  circle {
    fill: none;
    stroke: #2193b0;
    stroke-width: 3;
    stroke-dasharray: 183;
    stroke-dashoffset: 183;
    animation: circle 0.6s ease-in-out forwards;
  }
  path {
    fill: none;
    stroke: #2193b0;
    stroke-width: 3;
    stroke-dasharray: 96;
    stroke-dashoffset: 96;
    animation: check 0.6s ease-in-out 0.3s forwards;
  }
  @keyframes circle {
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes check {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export const SATConfirmation = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/SAT");
  };

  return (
    <ConfirmationContainer>
      <CheckmarkSVG viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="28" />
        <path d="M16 30l9 9 20-20" />
      </CheckmarkSVG>
      <Title>Thank You!</Title>
      <Message>
        We've successfully received your form submission. Your journey towards
        SAT excellence begins here!
      </Message>
      <Button onClick={handleClick}>Return to SAT Home Page</Button>
    </ConfirmationContainer>
  );
};
