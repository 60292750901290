import React from "react";
import { useState } from "react";
import {
  Navbar,
  MobileNav,
  IconButton,
  Button,
} from "@material-tailwind/react";
import { BarsArrowDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import "../Header/Header.css";
import logo from "../../images/Logo.png";
import title from "../../images/NALogo.png";

export const Header = ({ scrollToForm }) => {
  const [openNav, setOpenNav] = React.useState(false);
  const [showContactInfo, setShowContactInfo] = useState(true); // New state
  const [animationClass, setAnimationClass] = useState(""); // New state for animation

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );

    // Add an event listener to handle scroll events
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to handle scroll events
  const handleScroll = () => {
    // Set a scroll threshold at which to show the contact info
    const scrollThreshold = 150; // Adjust this value as needed

    // Check if the user has scrolled past the threshold
    if (window.scrollY >= scrollThreshold) {
      setAnimationClass("fade-out");
    } else {
      setAnimationClass("fade-in");
    }
  };

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-8 gap-6 items-center">
      <a
        href="/tutors"
        className="flex items-center hover:text-blue-500 transition-colors -ml-3 font-Poppins text-blue-gray text-lg font-bold"
      >
        Tutors
      </a>
      <a
        href="/blogs"
        className="flex items-center hover:text-blue-500 transition-colors -ml-3 font-Poppins text-blue-gray text-lg font-bold"
      >
        Blogs
      </a>
      <a
        href="mailto:northamericantutors@gmail.com"
        className="flex items-center hover:text-blue-500 transition-colors -ml-3 pr-6 font-Poppins text-blue-gray text-lg font-bold"
      >
        Join Us
      </a>
    </ul>
  );

  const navList2 = (
    <ul className="mb-4 mt-2 flex flex-col lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 gap-6 items-center">
      <a
        href="/tutors"
        className="flex items-center hover:text-blue-500 transition-colors -ml-3 sub-heading font-bold text-blue-gray text-xl"
      >
        Tutors
      </a>
      <a
        href="/blogs"
        className="flex items-center hover:text-blue-500 transition-colors -ml-3 sub-heading font-bold text-blue-gray text-xl"
      >
        Blogs
      </a>
      <a
        href="mailto:info@northamericantutors.com"
        className="flex items-center hover:text-blue-500 transition-colors -ml-3 sub-heading font-bold text-blue-gray text-xl"
      >
        Join Us
      </a>
      <a
        href="tel:+12368636213"
        className="flex items-center hover:text-blue-500 transition-colors font-Poppins font-bold text-lg sub-heading"
      >
        +12368636213
      </a>
      <Button
        variant="gradient"
        size="sm"
        className="w-auto h-auto bg-blue-900 text-white font-Poppins text-lg font-bold rounded-lg hover:text-blue-900 hover:bg-white"
        onClick={scrollToForm}
      >
        <span className="font-Poppins text-xl">HIRE A TUTOR </span>
      </Button>
    </ul>
  );

  return (
    <div>
      <Navbar className="mx-auto w-full top-0 z-10 bg-themeblue sticky top-0 rounded-none">
        <div className="container mx-auto flex items-center text-blue-gray-900">
          <a href="/" style={{ position: "absolute", top: 0 }}>
            <img src={logo} alt="logo-ct" className="w-30 h-20" />
          </a>
          <div className="flex-initial sm:pl-8"></div>
          <div className="flex-grow hidden lg:block"></div>
          <div className="flex-initial hidden lg:block">{navList}</div>
          <Button
            variant="gradient"
            size="sm"
            className="w-auto h-auto hidden lg:inline-block bg-blue-900 text-white font-Poppins font-bold rounded-lg hover:text-blue-900 hover:bg-white"
            onClick={scrollToForm}
          >
            <span className="font-bold font-Poppins text-lg">
              HIRE A TUTOR{" "}
            </span>
          </Button>
          <IconButton
            variant="text"
            className="ml-auto pr-7 mb-5 h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className="h-6 w-6" strokeWidth={2} />
            ) : (
              <BarsArrowDownIcon className="h-6 w-6" strokeWidth={2} />
            )}
          </IconButton>
        </div>
        <MobileNav open={openNav}>
          <div className="container mx-auto h-auto">{navList2}</div>
        </MobileNav>
      </Navbar>
      {showContactInfo}
    </div>
  );
};
