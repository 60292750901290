import React from "react";
import Faq from "react-faq-component";
import "../../components/Faq/Faq.css";

const data = {
  title: "Frequently Asked Questions",
  rows: [
    {
      title: "What are your tutoring rates?",
      content: `Introductory Session: <span style="font-weight:bold; color:#4682b4;">$50/Hr</span> (High School), <span style="font-weight:bold; color:#4682b4;">$60/Hr</span> (University). Our founder studied on a full-ride scholarship and, with limited financial resources, was able to put himself through school, owing a lot to his community. Now, our business operates by the motto "Education accessible for all." Book a lesson with us, and if we're a good fit, we'll ensure that budgeting for lessons is your last worry.`,
    },
    {
      title: "How can I track my child's progress?",
      content: `We keep you informed! Track your child's SAT progress with detailed tutor reports, personalized lesson plans, and calls to discuss their SAT prep and improvement. `,
    },
    {
      title: "How long does SAT prep take?",
      content: ` We recommend 3-6 months of preparation for optimal results. However, we also offer intensive programs for students with less time before their test date.`,
    },
    {
      title: "How is NAT better than all SAT tutors near me?",
      content: `Our expertise and personalized, one-on-one coaching set us apart. We understand that the SAT requires individualized instruction rather than group lessons, as every student has unique needs. Using our "sinking ship" method, we meticulously identify and address mistakes to ensure steady score improvement. Additionally, we offer an exclusive advantage with access to unreleased official SAT tests`,
    },
    {
      title: " What materials do you use for SAT prep?",
      content: ` We use a mix of official SAT practice materials, proprietary strategy guides, and custom practice questions to give you a competitive edge.`,
    },
  ],
};

export const SATFaqComponent = () => {
  const generateFaqSchema = () => {
    const faqSchema = data.rows.map((faq, index) => ({
      "@type": "Question",
      name: faq.title,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.content,
      },
    }));
    return {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: faqSchema,
    };
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="faq-style-wrapper">
        <Faq data={data} />
      </div>
    </div>
  );
};
