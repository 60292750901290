import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { TypeAnimation } from "react-type-animation";
import "../../components/Hero/Hero.css";

export const Hero = ({ scrollToForm }) => {
  // State to hold window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update window width value when window size changes
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if screen size is small (like a phone)
  const isPhoneScreen = windowWidth <= 768;

  return (
    <div className="flex justify-center">
      <div className="max-w-10xl w-full md:h-[500px] h-auto bg-themebanner flex flex-row justify-center md:mb-0 mb-10">
        <div className="bg-themebanner h-full md:min-w-[560px] md:w-[80%] text-center p-10 flex flex-col justify-center items-center">
          <p className="md:text-xl text-lg font-Nunito p-1 pl-2 t-16">
            <span className="text-sky-500">Kodawari </span> - The Pursuit to
            Perfection
          </p>
          <p className="md:text-6xl text-5xl font-Nunito p-1  ">Meet Your</p>
          <p className="md:text-6xl text-5xl font-Nunito p-1">Perfect</p>
          <p>
            <TypeAnimation
              sequence={[
                "Tutor",
                2000,
                "Mentor",
                2000,
                "Specialist",
                2000,
                "Guide",
                2000,
              ]}
              speed={10}
              deletionSpeed={50}
              repeat={Infinity}
              className="text-sky-500 text-center md:text-6xl text-5xl font-Poppins p-1"
            />
          </p>
          <p className="md:text-lg text-base font-Poppins p-1 ml-1">
            We Ensure
            <span className="md:text-lg text-base font-Poppins font-bold ml-1">
              YOUR
            </span>{" "}
            Learning is Personalised for
            <span className="text-lg font-Poppins font-bold ml-1">YOU</span>
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1px",
              width: isPhoneScreen ? "100%" : "50%",
              justifyContent: isPhoneScreen ? "center" : "flex-start",
              alignItems: "center", // Always center align items in this block
            }}
          >
            <Button
              onClick={scrollToForm}
              variant="gradient"
              size="sm"
              className="w-auto h-auto py-3 mt-5 bg-themedarkB font-nunito font-bold rounded-lg hover:bg-themelightB text-sm"
            >
              <span>Schedule Your FREE Lesson!</span>
            </Button>

            {isPhoneScreen && (
              <Button
                variant="gradient"
                size="sm"
                className="w-auto h-auto py-3 mt-5 bg-themedarkB font-nunito font-bold rounded-lg hover:bg-themelightB text-sm"
              >
                <a href="/tutors">Meet Our Tutors</a>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
