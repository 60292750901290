import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Analytics } from "@vercel/analytics/react";
import {
  Home,
  Tutors,
  AboutUs,
  Blogs,
  BlogContent,
  SAT,
  SATConfirmation,
} from "./screens/index";
import { clarity } from "react-microsoft-clarity";

// Initialize Microsoft Clarity
clarity.init("nltlirhnim");

const RootApp = () => (
  <Router>
    <Routes>
      <Route index element={<App />} />
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/tutors" element={<Tutors />} />
      <Route exact path="/aboutUs" element={<AboutUs />} />
      <Route exact path="/blogs" element={<Blogs />} />
      <Route path="/blogcontent/:id" element={<BlogContent />} />
      <Route path="/SAT" element={<SAT />} />
      <Route path="/SATConfirmation" element={<SATConfirmation />} />
      {/* <Route path="/sitemap.xml" element={<SitemapRoute />} />
      <Route path="/sitemap1.xml" element={<SitemapRoute />} /> */}
    </Routes>
    <Analytics />
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootApp />);

// Measure performance
reportWebVitals();
