import Stanford from "../../images/Stanford.png";
import Harvard from "../../images/Harvard1.png";
import Oxford from "../../images/Oxford.png";
import Cambridge from "../../images/Cambridge1.svg";
import MIT from "../../images/MIT.png";
import Tsinghua from "../../images/Tsinghua.png";

export const Banner = () => {
  return (
    <div className="flex justify-center">
      <div className="flex justifycenter max-w-8xl w-full h-auto bg-themebanner  flex lg:flex-row flex-col items-center justify-between px-3">
        <div>
          <h1 class="font-Poppins md:text-left text-justify text-md md:pl-7">
            TUTORS FROM AND STUDENTS ACCEPTED
            <br />
            INTO &nbsp;
            <span className="text-sky-600">THE WORLD'S TOP UNIVERSITIES</span>
          </h1>
        </div>

        <div className="flex flex-wrap justify-center items-center ">
          <img
            className="h-[60px] w-[170px] md:pr-10 pr-5"
            src={Stanford}
            alt="Stanford University Logo"
          ></img>
          <img
            className="h-[40px] w-[170px] md:pr-10 pr-5"
            src={Oxford}
            alt="Oxford University Logo"
          ></img>
          <img
            className="h-[70px] w-[170px] md:pr-10 pr-5"
            src={Harvard}
            alt="Harvard University Logo"
          ></img>
          <img
            className="h-[70px] w-[180px] md:pr-10  pr-5"
            src={Cambridge}
            alt="Cambridge University Logo"
          ></img>
          <img
            className="h-[70px] w-[170px] md:pr-10  pr-5"
            src={MIT}
            alt="MIT University Logo"
          ></img>
          <img
            className="h-[40px] w-[170px] md:pr-10  pr-5"
            src={Tsinghua}
            alt="Tsinghua University Logo"
          ></img>
        </div>
      </div>
    </div>
  );
};
