import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Header/Header.js";
import { Footer } from "../../components/Footer/Footer.js";
import { SATForm } from "../../components/Form/SATForm.js";
import { Banner } from "../../components/Banner/Banner.js";
import { Stats } from "../../components/Stats/Stats.js";
import { SATWhyUs } from "../../components/WhyUs/SATWhyUs.js";
import { SATHero } from "../../components/Hero/SATHero1.js";
import { SATFaqComponent } from "../../components/Faq/FaqSAT.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowNext from "../../images/next.png";
import ArrowPrev from "../../images/prev.png";
import { Button } from "@material-tailwind/react";
import ExitIntentPopup from "../../components/Exit-IntentPopUp/ExitPopUp.js";
import { staticReviews } from "../Home/staticReviews.js";
import { SATblogData } from "../Blogs/SATblogData";

export const SAT = () => {
  const formRef = useRef(null);
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [centerCardIndex, setCenterCardIndex] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const allreviews = staticReviews;
  const [showExitIntent, setShowExitIntent] = useState(false);
  const [count, setCount] = useState(0);

  const slider = React.useRef(null);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleMouseLeave = (event) => {
      if (event.clientY < 50 && window.innerWidth > 768 && count < 1) {
        setShowExitIntent(true);
      }
    };

    const handleBeforeUnload = (event) => {
      if (window.innerWidth <= 768 && count < 1) {
        setShowExitIntent(true);
        event.preventDefault();
        event.returnValue = "";
      }
    };

    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "hidden" &&
        window.innerWidth <= 768 &&
        count < 1
      ) {
        setShowExitIntent(true);
      }
    };

    document.addEventListener("mouseleave", handleMouseLeave);
    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [count]);

  const closePopup = () => {
    setCount(count + 1);
    setShowExitIntent(false);
  };

  const isPhoneScreen = windowWidth <= 768;

  const updateCenterCardIndex = (currentSlide) => {
    const totalCards = allreviews.length;
    const slidesToShow = settings.slidesToShow;
    let centerIndex = currentSlide + Math.floor(slidesToShow / 2);

    if (centerIndex >= totalCards) {
      centerIndex -= totalCards;
    }

    setCenterCardIndex(centerIndex);
  };

  const [allblogs, setAllBlogs] = useState(SATblogData);

  const settings = {
    infinite: true,
    speed: 500,
    adaptiveHeight: true,
    arrows: false,
    slidesToShow: screenWidth < 768 ? 1 : screenWidth < 1320 ? 2 : 3,
    slidesToScroll: 1,
  };

  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };

  return (
    <div className="bg-themebanner">
      {/* SEO Metadata */}
      <Helmet>
        <title>Private Tutoring & SAT Prep Near Me | Ivy League Tutors</title>
        <meta
          name="description"
          content="Expert SAT, AP, IB, A-Level, IGCSE, GCSE tutoring and college essay help. Online, private tutoring with Ivy League tutors for students worldwide."
        />
        <meta
          name="keywords"
          content="Online Tutors for AP, IB, SAT, IGCSE, GCSE, and College Admissions — Personalized for Every Student."
        />
        <meta
          property="og:title"
          content="Private Tutoring & SAT Prep Near Me | Ivy League Tutors"
        />
        <meta
          property="og:description"
          content="Expert SAT, AP, IB, A-Level, IGCSE, GCSE tutoring and college essay help. Online, private tutoring with Ivy League tutors for students worldwide."
        />
        <meta property="og:image" content="https://northamericantutors.com" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.northamericantutors.com" />
      </Helmet>

      <h1 className="sr-only">
        Private Tutoring & SAT Prep Near Me | IVY League Tutors
      </h1>

      <Header scrollToForm={scrollToForm} />
      <h1 className="hidden">
        North American Tutors - Expert SAT, IB, AP, GCSE Tutoring
      </h1>
      <SATHero scrollToForm={scrollToForm} />
      <h1 className="hidden">
        Personalized Online Tutoring to Help You Succeed
      </h1>
      <Banner />
      <div className="flex justify-center">
        <div className="max-w-10xl w-full mt-2 lg:px-20 px-8 bg-themebgwhite">
          <br />
          <div className="flex justify-center">
            <span className="text-4xl text-slate-800 font-Poppins font-bold md:mt-0 mt-12 text-center">
              Small in size. <span className="text-sky-500">Big on trust!</span>
            </span>
          </div>
          <br />

          <button
            className={`h-12 w-12 absolute lg:left-10 left-0 md:mt-72 mt-60 md:pr-2 pr-4 ${
              screenWidth >= 765 && screenWidth <= 863 ? "custom-margin1" : ""
            }`}
            onClick={() => slider?.current?.slickPrev()}
          >
            <img src={ArrowPrev} alt="Previous Slide" />
          </button>
          <button
            className={`h-12 w-12 absolute lg:right-10 right-0 md:mt-72 mt-60 md:pl-2 pl-4 ${
              screenWidth >= 768 && screenWidth <= 863 ? "custom-margin" : ""
            }`}
            onClick={() => slider?.current?.slickNext()}
          >
            <img src={ArrowNext} alt="Next Slide" />
          </button>

          <Slider
            {...settings}
            afterChange={updateCenterCardIndex}
            ref={slider}
          >
            {allreviews.map((review, index) => (
              <div className="flex md:ml-[10%] mb-6 mt-20" key={index}>
                <div
                  className={`justify-center flex relative flex-col items-center rounded-lg bg-themelightB md:h-[510px] h-auto md:w-[330px] lg:w-[370px] w-auto ${
                    index === centerCardIndex && settings.slidesToShow === 3
                      ? "card-pop-up"
                      : ""
                  } ${
                    screenWidth >= 1156 && screenWidth <= 1319
                      ? "custom-width"
                      : ""
                  }`}
                >
                  <div
                    className="absolute -top-12 w-36 h-36 overflow-hidden rounded-full bg-cover bg-no-repeat"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    <img
                      src={review.image}
                      className="w-full object-cover"
                      alt={`Review by ${review.name}`}
                    />
                  </div>
                  <div className="p-6 mt-24 md:-mt-10">
                    <h5 className="md:mt-28 mt-10 mb-2 text-xl font-bold font-Roboto text-white text-center">
                      {review.heading}
                    </h5>
                    <h5 className="font-Poppins text-lg text-white text-center">
                      {review.content}
                    </h5>
                    <br />
                    <h5 className="mb-2 text-lg font-bold font-Poppins text-white text-center">
                      {review.name}
                    </h5>
                    <h5 className="mb-2 text-lg font-Poppins text-white text-center">
                      {review.occupation}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </Slider>

          <h2 className="hidden">Why Choose Us?</h2>
          {/* {
            <div className="flex justify-center items-center">
              <Button
                variant="gradient"
                size="sm"
                className="w-auto h-auto py-3 mt-5 bg-themedarkB font-nunito font-bold rounded-lg hover:bg-themelightB text-sm"
              >
                <a href="/tutors">Meet Our Tutors</a>
              </Button>
            </div>
          } */}
          <SATWhyUs />
          <br />
          {isPhoneScreen && (
            <div className="flex justify-center items-center">
              <Button
                onClick={scrollToForm}
                variant="gradient"
                size="sm"
                className="w-auto h-auto py-3 mt-5 bg-themedarkB font-nunito font-bold rounded-lg hover:bg-themelightB"
              >
                <span>Schedule Your FREE Lesson!</span>
              </Button>
            </div>
          )}

          <br />
          <Stats />
          <br />
          <br />
          <br />
        </div>
      </div>
      <SATForm formRef={formRef} />
      <SATFaqComponent />
      <div className="w-full bg-[#f9f9f9] py-[50px]">
        <div className="flex justify-center">
          <span className="text-4xl text-slate-800 font-Poppins font-bold text-center">
            Latest from the Blog
          </span>
        </div>
        <br />
        <br />
        <div className="mb-10 max-w-[1240px] mx-auto">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 px-4 text-black">
            {allblogs.map((blog) => (
              <Link key={blog._id} to={`/blogcontent/${blog.slug}`}>
                <div className="bg-white rounded-xl overflow-hidden drop-shadow-md md:h-[400px]">
                  <img
                    className="h-56 w-full object-cover"
                    src={blog.coverImg}
                    alt={`Blog: ${blog.blogTitle}`}
                  />
                  <div className="p-8">
                    <h3 className="font-bold text-2xl my-1 font-Poppins">
                      {blog.blogTitle}
                    </h3>
                    <p className="text-gray-600 text-l sub-heading">
                      {blog.blogDesc}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="flex justify-center">
          <a
            href="/blogs"
            className="bg-themelightB px-5 py-2 font-Poppins font-bold text-lg text-blue-gray-500 border border-slate-400 rounded-lg"
          >
            <span className="text-white text-center">View All</span>
          </a>
        </div>
      </div>
      <Footer />
      {showExitIntent && <ExitIntentPopup onClose={closePopup} />}
    </div>
  );
};
