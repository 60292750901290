import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Form } from "../../components/Form/Form";
import axios from "axios";
import { blogData } from "../Blogs/blogData"; // Import the first 3 blogs

export const Blogs = () => {
  const formRef = useRef(null);
  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [blogs, setBlogs] = useState(blogData); // Start with first 3 blogs
  const [page, setPage] = useState(1); // Pagination starts from 1
  const [totalPages, setTotalPages] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);

  const loadMoreBlogs = async () => {
    try {
      setLoadingMore(true);
      const { data } = await axios.get(
        `https://nat-backend.vercel.app/getblogs?page=${page}`
      );

      if (data && data.blogs) {
        setBlogs((prev) => [...prev, ...data.blogs]); // Append new blogs
        setTotalPages(data.totalPages);
        setPage((prev) => prev + 1); // Increment page only after a successful fetch
      } else {
        throw new Error("Invalid response format");
      }
    } catch (err) {
      setError("Error fetching blogs");
    } finally {
      setLoadingMore(false);
    }
  };

  return (
    <div className="bg-themebgwhite">
      {/* Visually hidden heading for SEO (using sr-only class) */}
      <h1 className="sr-only">
        Expert SAT, ACT AP, IB, A-Level Tutoring & College Admissions Tips | Ivy
        League Tutors{" "}
      </h1>
      <Helmet>
        <title>
          Expert SAT, ACT, AP, IB, A-Level Tutoring & College Admissions Tips |
          Ivy League Tutors
        </title>
        <meta
          name="description"
          content="Get expert tips on college essay reviews, homeschool programs, and college admissions. Ivy League tutors help you excel in SAT, AP, IB, A-Level, and more."
        />
        <meta
          property="og:description"
          content="Get expert tips on college essay reviews, homeschool programs, and college admissions. Ivy League tutors help you excel in SAT, AP, IB, A-Level, and more."
        />
      </Helmet>

      <Header scrollToForm={scrollToForm} />

      <div className="w-full bg-[#f9f9f9] py-10">
        <div className="max-w-[1240px] mx-auto">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 px-4 text-black">
            {blogs.map(
              ({
                _id,
                slug,
                coverImg,
                blogTitle,
                blogDesc,
                category,
                authorImg,
                authorName,
                authorDesc,
              }) => (
                <Link key={_id || slug} to={`/blogcontent/${slug}`}>
                  <div className="bg-white rounded-xl shadow-md flex flex-col">
                    <img
                      className="h-40 w-full object-cover"
                      src={coverImg}
                      alt="Expert SAT, ACT AP, IB, A-Level Tutoring & College Admissions Tips | Ivy League Tutors"
                    />
                    <div className="p-6 flex-1 flex flex-col">
                      <h3 className="font-bold text-xl text-[#132043]">
                        {blogTitle}
                      </h3>
                      <p className="text-gray-600">{blogDesc}</p>
                      <div className="mt-2 text-sm text-[#4e5683]">
                        Category: {category}
                      </div>
                      <div className="mt-4 flex items-center">
                        <img
                          src={authorImg}
                          alt="Author"
                          className="w-8 h-8 rounded-full mr-2"
                        />
                        <div>
                          <h4 className="text-[#132043] font-bold">
                            {authorName}
                          </h4>
                          <p className="text-[#4e5683] text-xs">{authorDesc}</p>
                        </div>
                      </div>
                      <Link
                        to={`/blogcontent/${slug}`}
                        className="mt-4 text-[#e07a5a] font-bold"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </Link>
              )
            )}
          </div>
        </div>
      </div>

      {error && <div className="text-center text-red-500 my-4">{error}</div>}

      {page <= totalPages && (
        <div className="text-center mt-8 mb-8">
          <button
            className="px-6 py-3 bg-themedarkB text-white font-bold text-lg rounded-lg hover:bg-blue-800 transition"
            onClick={loadMoreBlogs}
            disabled={loadingMore}
          >
            {loadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      )}

      <Form formRef={formRef} />
      <Footer />
    </div>
  );
};

export default Blogs;
