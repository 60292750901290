import React, { useState } from "react";
import axios from "axios";
import { Button } from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Country } from "country-state-city";

export const SATHero = () => {
  const [activeButton, setActiveButton] = useState(null);
  const [selected, setSelected] = useState({ name: "Select Your Country" });
  const [query, setQuery] = useState("");
  const [fullname, setFullname] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);

  const countryData = Country.getAllCountries();

  const filteredCountries =
    query === ""
      ? countryData
      : countryData.filter((c) =>
          c.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const button1click = () => {
    setActiveButton(1);
  };

  const button2click = () => {
    setActiveButton(2);
  };

  const sendtoDB = () => {
    setLoading(true);
    const currentDate = new Date().toLocaleString();
    // Append the prefix "SAT:" to the fullname upon submission.
    const storedName = `SAT: ${fullname}`;

    axios({
      method: "post",
      url: "https://nat-backend.vercel.app/customerdetails",
      data: {
        classification: activeButton === 1 ? "Parent" : "Student",
        fullname: storedName,
        selectedCountry,
        phonenumber,
        email,
        details,
        currentDate,
      },
    })
      .then(() => {
        toast.success("Form submitted successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Trigger Google Ads conversion
        if (
          typeof window !== "undefined" &&
          typeof window.gtag === "function"
        ) {
          window.gtag("event", "conversion", {
            send_to: "AW-16875480295/MYVHCKuDh6gaEOfJ7e4-", // Replace with your actual conversion ID
            value: 25.0,
            currency: "CAD",
          });
        }

        // Reset form fields after submission
        setActiveButton(null);
        setFullname("");
        setSelectedCountry("");
        setEmail("");
        setPhonenumber("");
        setDetails("");
      })
      .catch((err) => {
        toast.error(err.response.data.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className="py-12 bg-gray-50">
      <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center">
        {/* Left Side: Marketing Content */}
        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:pr-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Help Your Child Achieve Their Dream SAT Score and Get Into Top
            Colleges
          </h1>
          <p className="text-lg text-gray-700 mb-6">
            A niche tutoring agency founded by IVY-League tutors who left big
            firms to bring back the art of teaching—because tutoring should be
            personal, not just a business.
          </p>
          <ul className="space-y-6">
            <li className="flex items-start">
              <span className="text-green-500 text-2xl mr-3">✓</span>
              <div>
                <h3 className="text-xl font-semibold text-gray-800">
                  Our Students Improve by 170+ Points on Average
                </h3>
                <p className="text-gray-600">
                  Expert tutors from top universities. All our tutors are from
                  Harvard, MIT, Stanford and other elite schools.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-green-500 text-2xl mr-3">✓</span>
              <div>
                <h3 className="text-xl font-semibold text-gray-800">
                  Proven Results for SAT Math and English
                </h3>
                <p className="text-gray-600">
                  98% of our students see improvements in their results.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-green-500 text-2xl mr-3">✓</span>
              <div>
                <h3 className="text-xl font-semibold text-gray-800">
                  Personalized Learning Approach (1-on-1)
                </h3>
                <p className="text-gray-600">
                  Customized study plans that target your child's specific
                  weaknesses.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-green-500 text-2xl mr-3">✓</span>
              <div>
                <h3 className="text-xl font-semibold text-gray-800">
                  Weekly Progress Reports for Parents
                </h3>
                <p className="text-gray-600">
                  Stay informed about your child's improvement with detailed
                  updates.
                </p>
              </div>
            </li>
          </ul>
        </div>

        <div className="w-full lg:w-1/2">
          <div className="bg-white p-8 rounded-md shadow-md">
            <div className="flex items-center justify-center space-x-0 lg:space-x-5 flex-col lg:flex-row space-y-5 lg:space-y-0">
              <div
                className={`bg-white lg:w-[45%] w-[90%] py-3 text-lg flex justify-center items-center font-Poppins text-blue-gray-500 border border-slate-400 cursor-pointer rounded-md ${
                  activeButton === 1 ? "active" : ""
                }`}
                onClick={button1click}
              >
                <span>I am a Parent</span>
              </div>
              <div
                className={`bg-white lg:w-[45%] w-[90%] py-3 text-lg flex justify-center items-center font-Poppins text-blue-gray-500 border border-slate-400 cursor-pointer rounded-md ${
                  activeButton === 2 ? "active" : ""
                }`}
                onClick={button2click}
              >
                <span>I am a Student</span>
              </div>
            </div>
            <br />
            <div className="flex space-x-4 mb-4">
              <div className="flex flex-col w-full">
                <label className="text-gray-800 font-semibold">Full Name</label>
                <input
                  type="text"
                  className="p-3 w-full border-2 rounded-md"
                  placeholder="Your Name"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>

              <div className="flex flex-col w-full">
                <label className="text-gray-800 font-semibold">Country</label>
                <input
                  type="text"
                  className="p-3 w-full border-2 rounded-md"
                  placeholder="Your Country"
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                />
              </div>
            </div>
            {/* Phone and Email - Side by Side */}
            <div className="flex space-x-4 mb-4">
              <div className="flex flex-col w-full">
                <label className="text-gray-800 font-semibold">Phone</label>
                <input
                  type="text"
                  className="p-3 w-full border-2 rounded-md"
                  placeholder="Your Phone Number"
                  value={phonenumber}
                  onChange={(e) => setPhonenumber(e.target.value)}
                />
              </div>

              <div className="flex flex-col w-full">
                <label className="text-gray-800 font-semibold">Email</label>
                <input
                  type="email"
                  className="p-3 w-full border-2 rounded-md"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            {/* Details Field */}
            <div className="flex flex-col w-full mb-4">
              <label className="text-gray-800 font-semibold">
                Where do you want to succeed? Tell us more!
              </label>
              <input
                className="p-3 w-full border-2 rounded-md"
                placeholder="Your goals"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
            </div>
            <Button
              type="button"
              variant="gradient"
              onClick={sendtoDB}
              className="bg-blue-600 hover:bg-blue-700 w-full py-2 text-white font-bold text-lg"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Book a Call"}
            </Button>
            <div className="text-sm text-gray-500 mt-4 text-center">
              <p>
                No obligation. Get expert advice on how to improve your child's
                SAT score. Subject to availability.
              </p>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </section>
  );
};

export default SATHero;
