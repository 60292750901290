import React from "react";

function ExitIntentPopup({ onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-2xl p-6 md:p-12 mx-4 relative">
        <div
          className="absolute top-0 right-0 p-6 cursor-pointer"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-gray-500 hover:text-gray-700"
            fill="none"
            viewBox="0 0 20 20"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div className="text-center">
          <h2 className="text-3xl md:text-4xl font-extrabold text-sky-900 mb-4">
            Not Sure If You Need Tutoring?
            <h2 className="text-3xl text-sky-500 md:text-4xl font-extrabold text-sky-500 mb-4">
              Still Unsure About Pricing or Quality?
            </h2>
          </h2>

          <div className="p-6 rounded-lg mb-8">
            <p className="text-lg md:text-xl text-gray">
              Experience a free lesson and see why our tutoring stands out. No
              obligations, just quality education.
              <br />
            </p>
          </div>
          <div className="relative inline-block">
            <button
              className="w-full md:w-auto h-auto bg-themedarkB text-white font-Poppins text-lg font-bold rounded-lg px-6 py-3 hover:bg-[#FFC501] transition-transform transform hover:scale-105 shadow-md hover:shadow-lg"
              onClick={() =>
                (window.location.href =
                  "https://calendly.com/northamericantutors/call")
              }
              style={{ padding: "1rem 3rem" }} // Increases the clickable area
            >
              Schedule Your Free Lesson
            </button>
          </div>
          <div className="mt-6">
            <logo className="mx-auto h-16 md:h-20" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExitIntentPopup;
